<!--
    /**
     * 下拉选择树形组件，下拉框展示树形结构，提供选择某节点功能，方便其他模块调用
     * @author lgh
     * @date 2020-09-01
     * 调用示例：
     * <tree-select :height="400" // 下拉框中树形高度
     *              :width="200" // 下拉框中树形宽度 （废除)
     *              :data="data" // 树结构的数据
     *              :defaultProps="defaultProps" // 树结构的props
     *              multiple   // 多选
     *              :rootNodeChick="true" // 是否可以选择根节点。默认 false ture 为可选。false 为不可选
     *              checkStrictly // 多选时，严格遵循父子不互相关联
     *              :nodeKey="nodeKey"   // 绑定nodeKey，默认绑定'id'
     *              :checkedKeys="defaultCheckedKeys"  // 传递默认选中的节点key组成的数组
     *              @popoverHide="popoverHide"> // 事件有两个参数：第一个是所有选中的节点ID，第二个是所有选中的节点数据
     *              </tree-select>
     *
     * import TreeSelect from "@/utils/components/tree-select.vue";
     * components: { TreeSelect },
     *
     *数据格式
     *let obj = {
                  menuId: item.id,
                  menuName: item.label,
                  labelIcon: item.labelIcon,
                  childrenList: children
                };
     * 清空树的选中状态。只需要将clear 从 0 累加就可以。这里是监听的数据改变状态。不为 0 则清空数据。Number类型
     */
-->
<template>
  <div>
    <div
      class="mask"
      v-show="isShowSelect"
      @click="isShowSelect = !isShowSelect"
    ></div>
    <el-popover
      popper-class="select-width"
      placement="bottom-start"
      :width="selectWidth"
      trigger="manual"
      v-model="isShowSelect"
      @hide="popoverHide"
      clearable
    >
      <el-tree
        class="common-tree"
        :style="style"
        clearable
        ref="tree"
        :data="data"
        :props="defaultProps"
        :show-checkbox="multiple"
        :node-key="nodeKey"
        :check-strictly="checkStrictly"
        default-expand-all
        :expand-on-click-node="false"
        :default-checked-keys="defaultCheckedKeys"
        :highlight-current="true"
        :render-content="renderContent"
        @node-click="handleNodeClick"
        @check-change="handleCheckChange"
      >
      </el-tree>
      <el-select
        :disabled="disabled"
        :placeholder="placeholderText"
        :style="selectStyle"
        slot="reference"
        ref="select"
        v-model="selectedData"
        :multiple="multiple"
        clearable
        @click.native="isShowSelect = !isShowSelect"
        class="tree-select w-full"
      >
      </el-select>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "tree-select",
  // props: ["clear"],
  props: {
    // 占位文字
    placeholderText: {
      type: String,
      default: "请选择"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 树结构数据
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否可选根节点
    rootNodeChick: Boolean,
    // 是否清空数据
    clear: Number,
    defaultProps: {
      type: Object,
      default() {
        return {};
      }
    },
    // 配置是否可多选
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    nodeKey: {
      type: String,
      default() {
        return "id";
      }
    },
    // 显示复选框情况下，是否严格遵循父子不互相关联
    checkStrictly: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 默认选中的节点key数组
    checkedKeys: {
      type: Array,
      default() {
        return [];
      }
    },
    width: {
      type: Number,
      default() {
        return 350;
      }
    },
    height: {
      type: Number,
      default() {
        return 300;
      }
    }
  },
  data() {
    return {
      selectWidth: "",
      defaultCheckedKeys: [],
      isShowSelect: false, // 是否显示树状选择器
      options: [],
      selectedData: [], // 选中的节点
      style: "height:" + this.height + "px;",
      selectStyle: "width:" + (this.selectWidth + 24) + "px;",
      checkedIds: [],
      checkedData: []
    };
  },
  mounted() {
    if (this.checkedKeys.length > 0) {
      if (this.multiple) {
        this.defaultCheckedKeys = this.checkedKeys;
        this.selectedData = this.checkedKeys.map(item => {
          var node = this.$refs.tree.getNode(item);
          return node.label;
        });
      } else {
        var item = this.checkedKeys[0];
        this.$refs.tree.setCurrentKey(item);
        var node = this.$refs.tree.getNode(item);
        this.selectedData = node.label;
      }
    }
  },
  methods: {
    popoverHide() {
      if (this.multiple) {
        this.checkedIds = this.$refs.tree.getCheckedKeys(); // 所有被选中的节点的 key 所组成的数组数据
        this.checkedData = this.$refs.tree.getCheckedNodes(); // 所有被选中的节点所组成的数组数据
      } else {
        this.checkedIds = this.$refs.tree.getCurrentKey();
        this.checkedData = this.$refs.tree.getCurrentNode();
      }
      this.$emit("popoverHide", this.checkedIds, this.checkedData);
    },
    // 节点被点击时的回调,返回被点击的节点数据
    handleNodeClick(data, node) {
      if (!this.multiple) {
        let tmpMap = {};
        tmpMap.value = node.key;
        tmpMap.label = node.label;
        this.options = [];
        this.options.push(tmpMap);
        this.selectedData = node.label;
        this.isShowSelect = !this.isShowSelect;
      }
    },
    // 节点选中状态发生变化时的回调
    handleCheckChange() {
      var checkedKeys = this.$refs.tree.getCheckedKeys(); // 所有被选中的节点的 key 所组成的数组数据
      this.options = [];
      if (!this.rootNodeChick)
        checkedKeys.forEach(item => {
          var node = this.$refs.tree.getNode(item); // 所有被选中的节点对应的node
          let tmpMap = {};
          if (node.childNodes.length == 0) {
            tmpMap.value = node.key;
            tmpMap.label = node.label;
            this.options.push(tmpMap);
          }
        });
      else
        this.options = checkedKeys.map(item => {
          var node = this.$refs.tree.getNode(item); // 所有被选中的节点对应的node
          let tmpMap = {};
          tmpMap.value = node.key;
          tmpMap.label = node.label;
          return tmpMap;
        });
      this.selectedData = this.options.map(item => {
        return item.label;
      });
    },
    // 追加结构
    renderContent(h, { node }) {
      let item = node.data;
      if (item.labelIcon) {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span class="tree-node-icon">
              <i class={[`${item.labelIcon}`]}></i>
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
          </span>
        );
      }
    }
  },
  watch: {
    isShowSelect() {
      // console.log(val);
      // 添加下拉树加个宽度
      this.selectWidth = this.$refs.select.$el.clientWidth;
      // 隐藏select自带的下拉框
      this.$refs.select.blur();
    },
    clear: function(n) {
      //箭头函数  不然会发生this改变
      if (n != 0) {
        this.selectedData = [];
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys([]);
        });
      }
    },
    selectedData: function(newData) {
      // console.log(oldData);
      this.popoverHide();
      if (
        newData == undefined ||
        newData == null ||
        newData == [] ||
        newData.length == 0
      )
        this.$refs.tree.setCheckedKeys([]);
    }
  }
};
</script>

<style scoped>
.select-width {
  /* width: 35%; */
  min-width: 300px;
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}
.common-tree {
  overflow: auto;
}
</style>

<style>
.tree-node-icon {
  position: absolute;
  left: 2px;
}
.tree-select .el-input {
  font-size: 0.8rem;
}
.tree-select .el-input__inner {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.tree-select .el-select__tags .el-tag .el-tag__close {
  display: none;
}
.tree-select .el-select__tags .el-tag .el-icon-close {
  display: none;
}
.el-input.is-disabled .el-input__inner {
  background-color: #fafafa !important;
}
</style>
