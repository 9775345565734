<!-- eslint-disable prettier/prettier -->
<!-- 
    页面 账户信息 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="account-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vs-button size="small" type="relief" @click="createData">新增账户</vs-button>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="users" ref="usersTable" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
              <span>{{ (pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column property="account" label="登录账号" width="200" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="roleLisText" label="所属权限" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="240" header-align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" :disabled="scope.row.account == 'admin'" @click="authData(scope.row)">权限管理</el-button>
              <!-- <el-button type="text" size="small" class="primary" @click="watchData(scope.row)">详情</el-button> -->
              <el-button type="text" size="small" class="primary" :disabled="scope.row.account == 'admin'" @click="resetPwdData(scope.row)">重置密码</el-button>
              <el-button type="text" size="small" class="primary" :disabled="scope.row.account == 'admin'" @click="editData(scope.row)">编辑角色</el-button>
              <el-button type="text" size="small" class="primary" :disabled="scope.row.account == 'admin'" @click="delData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination class="pagination" layout="total, sizes, prev, pager, next" prev-text="上一页" next-text="下一页" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageOpt.currentPage" :page-sizes="pageOpt.pageSizes" :page-size="pageOpt.currentPageSize" :pager-count="pageOpt.pageCount" :total="pageOpt.totalPageSize" :hide-on-single-page="pageOpt.hideSinglePage"></el-pagination>
        </div>
      </div>
    </vx-card>
    <!-- 弹出框 -->
    <accountDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible" @handleDiaLogClose="handleDiaLogClose(arguments)"></accountDialogCURD>
  </div>
</template>

<script>
// 导入弹框
import accountDialogCURD from './AccountDialogCURD'

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      users: [],
      roleList: [],
      villages: [],
      // 加载状态
      loading: false,
      // 弹出框状态
      dialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: '',
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    }
  },
  methods: {
    // 创建数据
    createData() {
      // console.log("创建数据");
      this.dialogFormOpt = this.$CONST.OPTRATE.C
      this.dialogFormVisible = true
      this.dialogFormData = Object.assign({ roleList: this.roleList }, { defaultCheckedKeys: [] }, {})
    },
    // 修改权限
    authData(row) {
      let spaces = this.villages
      this.dialogFormOpt = this.$CONST.OPTRATE.A
      this.dialogFormVisible = true
      let spacesCheckedKeys = this.traversalSpacesCheckedKeys(row.spacelist)
      this.dialogFormData = Object.assign({ spacesAllList: this.traversalLabelIcon(spaces, 'el-icon-guide') }, { spacesDefaultCheckedKeys: spacesCheckedKeys }, row)
    },
    // 重置密码
    resetPwdData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.RP
      this.dialogFormVisible = true
      this.dialogFormData = Object.assign({}, row)
    },
    // 查看当前列数据
    watchData(row) {
      // console.log("查看数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.R
      this.dialogFormVisible = true
      let checkedKeys = this.traversalCheckedKeys(this.roleList, row.roleLisIDs)
      let disableList = JSON.parse(JSON.stringify(this.roleList))
      this.recurisonMenu(disableList)
      this.dialogFormData = Object.assign({ roleList: disableList }, { defaultCheckedKeys: checkedKeys }, row)
    },
    // 编辑当前列数据
    editData(row) {
      // console.log("编辑数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.U
      this.dialogFormVisible = true
      let checkedKeys = this.traversalCheckedKeys(this.roleList, row.roleLisIDs)
      this.dialogFormData = Object.assign({ roleList: this.roleList }, { defaultCheckedKeys: checkedKeys }, row)
    },
    // 删除当前列数据
    delData(row) {
      // console.log("删除数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.D
      this.dialogFormVisible = true
      let checkedKeys = this.traversalCheckedKeys(this.roleList, row.roleLisIDs)
      let disableList = JSON.parse(JSON.stringify(this.roleList))
      this.recurisonMenu(disableList)
      this.dialogFormData = Object.assign({ roleList: disableList }, { defaultCheckedKeys: checkedKeys }, row)
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1 + (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize
    },
    // 列表选中
    handleSelectionChange(val) {
      this.selected = val
      // console.log("角色列表选中:", this.selected);
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val
      this.getUserList()
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val
      this.getUserList()
    },
    // 当子模态框关闭回调
    handleDiaLogClose(isRefresh) {
      // console.log(isRefresh);
      this.dialogFormVisible = false
      if (isRefresh[0]) this.getUserList()
    },
    // 加载动画
    loadAnimation() {
      this.loading = true
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false
          resolve(true)
        }, 1000)
      })
    },
    // 请求接口
    getUserList() {
      this.loading = true
      // 请求数据
      this.$apis
        .ODC('获取用户列表', {
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        })
        .then(
          result => {
            if (this.$apis.isOk(result)) {
              let users = result.data.users
              users.forEach(item => {
                let roleName = ''
                let roleId = ''
                item.rolelist.forEach((itemChild, index) => {
                  roleId += index >= 1 ? ',' + itemChild.role_id : itemChild.role_id
                  roleName += index >= 1 ? ', ' + itemChild.role_name : itemChild.role_name
                })
                item.roleLisText = roleName
                item.roleLisIDs = roleId
              })
              this.users = result.data.users
              this.pageOpt.totalPageSize = result.data.count
            }
            setTimeout(() => {
              this.loading = false
            }, 500)
          },
          err => {
            setTimeout(() => {
              this.loading = false
            }, 500)
            this.$notify({
              time: 2500,
              title: 'Error',
              text: this.$apis.getResMessage(err),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        )
    },
    // 获取角色列表
    async getRoleList() {
      try {
        let result = await this.$apis.ODC('获取角色', {
          pageNum: 1,
          pageSize: 999
        })
        if (this.$apis.isOk(result)) {
          let menus = result.data.roleList
          if (menus.length > 0) {
            this.roleList = this.traversalLabelIcon(menus, 'el-icon-user')
          }
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 请求小区(空间)列表  获取用户区域权限
    async getVillageList() {
      try {
        let params = {
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        }
        let result = await this.$apis.ODC('获取用户区域权限', params)
        if (this.$apis.isOk(result)) {
          this.villages = result.data
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 添加icon 给没有children 的一级节点 ( el-ui 上找的 )
    traversalLabelIcon(array, icon) {
      let arr = array.map(item => {
        if (!item.children) {
          item.labelIcon = icon
        }
        return item
      })
      return arr
    },
    // 递归添加 (改动小可以使用递归)
    recurisonMenu(array) {
      for (let item of array) {
        // 改变数据格式
        item.disabled = true
        // 判断下子集
        if (item.children) {
          this.recurisonMenu(item.children)
        }
      }
    },
    // 选取勾选的key (移除有子集未全部勾选的key) (暂时只支持3级)
    traversalCheckedKeys(meuns, functionID) {
      let defaultCheckedKeys
      let functionArr = (defaultCheckedKeys = typeof functionID === 'string' ? functionID.split(',') : [])
      for (let item of meuns) {
        // 判断下子集
        if (item.children) {
          for (let childrenItem of item.children) {
            if (!functionArr.includes(childrenItem.id)) {
              let fIndex = defaultCheckedKeys.findIndex(item => item == childrenItem.p_id)
              if (fIndex > -1) {
                defaultCheckedKeys.splice(fIndex, 1)
              }
            }
          }
        }
      }
      return defaultCheckedKeys
    },
    traversalSpacesCheckedKeys(spaces) {
      let defaultCheckedKeys = []
      for (let item of spaces) {
        defaultCheckedKeys.push(item.space_code)
      }
      return defaultCheckedKeys
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = ''
      switch (state) {
        case 'success':
          title = '成功'
          color = 'success'
          break
        case 'danger':
          title = '错误'
          color = 'danger'
          break
        case 'warning':
          title = '警告'
          color = 'warning'
          break
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    }
  },
  created() {
    // 请求数据
    this.getUserList()
    this.getRoleList()
    this.getVillageList()
  },
  components: {
    accountDialogCURD
  }
}
</script>

<style lang="scss" scope="scope">
.table_height_container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
}
.el-button.is-disabled.el-button--text {
  text-decoration: line-through;
}
</style>
