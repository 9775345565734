<template>
  <el-dialog customClass="customWidth" :visible.sync="isVisible" :destroy-on-close="true" :show-close="true" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>
    <!-- 弹窗内容 -->
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <!-- 表单认证(登录账号) -->
      <el-form-item label="登录账号" prop="user_account">
        <el-input v-model="form.user_account" prefix-icon="el-icon-user" placeholder="请输入登录账号" autocomplete="off" :disabled="isDisableAccount" @input="e => (form.user_account = validForbid(e))"></el-input>
      </el-form-item>

      <!-- 表单认证(登录密码) -->
      <div style="position: relative">
        <el-form-item label="登录新密码" prop="user_password" v-if="isShowPwd">
          <el-input :type="pwdInputType" v-model="form.user_password" prefix-icon="el-icon-user" placeholder="请输入登录新密码" autocomplete="off" :disabled="isDisable"></el-input>
        </el-form-item>
        <span v-if="isShowPwd" @click.stop="changePasswordType($event)" style="cursor:pointer; position: absolute; top:33px; right: 15px;">
          <vs-icon size="small" :icon="pwdIconType"></vs-icon>
        </span>
      </div>

      <!-- 表单认证(角色权限) -->
      <div v-if="isShowAuth" class="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary">
        <label for="" class="vs-input--label el-form-item__label">
          所属角色
          <span style="font-size: 0.6rem;">(至少一个)</span>
        </label>
        <tree-select multiple v-if="reFresh" placeholderText="选择角色" :disabled="isDisable" :data="treeSelect.dataTreeList" :defaultProps="treeSelect.defaultProps" :clear="treeSelect.clearTreeSelect" :nodeKey="treeSelect.nodeKey" :checkedKeys="treeSelect.defaultCheckedKeys" :height="300" @popoverHide="popoverHide" @parent-event="popoverHide"></tree-select>
      </div>

      <!-- 表单认证(账户状态) -->
      <div v-if="isOperateAuth" class="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary">
        <label for="" class="vs-input--label">
          账户状态
          <span style="font-size: 0.6rem;">(默认:启用)</span>
        </label>
        <div>
          <el-radio-group v-model="form.is_lock" size="small">
            <el-radio label="1" border>账户启用</el-radio>
            <el-radio label="2" border>账户禁用</el-radio>
          </el-radio-group>
        </div>
      </div>

      <!-- 表单认证(空间控制) -->
      <div v-if="isOperateAuth" class="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary">
        <label for="" class="vs-input--label">
          社区控制
          <span style="font-size: 0.6rem;">(默认:全部)</span>
        </label>
        <div>
          <el-radio-group v-model="form.is_restrict_spaces" size="small">
            <el-radio label="1" border>控制全部</el-radio>
            <el-radio label="2" border>控制部分</el-radio>
          </el-radio-group>
        </div>
      </div>

      <!-- 表单认证(勾选空间 只有当上级选则部分时候才会出现) -->
      <div v-if="form.is_restrict_spaces == 2 && isOperateAuth" class="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary">
        <label for="" class="vs-input--label">
          可控社区
          <span style="font-size: 0.6rem;">(至少一个)</span>
        </label>
        <tree-select multiple v-if="reFresh" :disabled="isDisable" placeholderText="选择社区" :data="spacesTreeSelect.dataTreeList" :defaultProps="spacesTreeSelect.defaultProps" :clear="spacesTreeSelect.clearTreeSelect" :nodeKey="spacesTreeSelect.nodeKey" :checkedKeys="spacesTreeSelect.defaultCheckedKeys" :height="300" @popoverHide="spacesPopoverHide" @parent-event="spacesPopoverHide"></tree-select>
      </div>
    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button size="small" class="mr-5" type="relief" color="grey" @click="handleClose">
        取 消
      </vs-button>
      <vs-button size="small" type="relief" color="danger" class="mr-5 vs-con-loading__container" @click="delRow" v-if="isDisable && this.operate == 'DELETE'">
        删除
      </vs-button>
      <vs-button size="small" type="relief" color="primary" :disabled="!validateForm" class="vs-con-loading__container" @click="submitForm('ruleForm')" v-if="!isDisable">
        确 定
      </vs-button>
    </span>
  </el-dialog>
</template>

<script>
import TreeSelect from '@/components/el-tree-select/ElTreeSelect.vue'
import md5 from 'js-md5'

export default {
  props: ['dialogFormVisible', 'dialogFormData', 'dialogFormOpt'],
  data() {
    var checkPassword = (rule, value, callback) => {
      let regex = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}/
      // 字母连续规则
      let strReg = /(a(?=b)|b(?=c)|c(?=d)|d(?=e)|e(?=f)|f(?=g)|g(?=h)|h(?=i)|i(?=j)|j(?=k)|k(?=l)|l(?=m)|m(?=n)|n(?=o)|o(?=p)|p(?=q)|q(?=r)|r(?=s)|s(?=t)|t(?=u)|u(?=v)|v(?=w)|w(?=x)|x(?=y)|y(?=z)|z(?=a)){3}[a-z]/i
      // 数字连续规则
      let numReg = /(0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){3}\d/
      // 键盘字母横向连续规则
      let keyboardHorizontalReg = /(q(?=w)|w(?=e)|e(?=r)|r(?=t)|t(?=y)|y(?=u)|u(?=i)|i(?=o)|o(?=p)|p(?=q) |a(?=s)|s(?=d)|d(?=f)|f(?=g)|g(?=h)|h(?=j)|j(?=k)|k(?=l)|l(?=a) | z(?=x)|x(?=c)|c(?=v)|v(?=b)|b(?=n)|n(?=m)|m(?=z)){3}[a-z]/i
      // 多个相同字母、数字规则
      let sameReg = /(\w)\1{3}/i

      /**
       * 键盘斜线的4个字符是否在pwd里面
       */
      function isStrInSlope(pwd) {
        let keyboardSlopeArr = ['1qaz', '2wsx', '3edc', '4rfv', '5tgb', '6yhn', '7ujm', '8ik,', '9ol.', '0p;/', '=[;.', '-pl,', '0okm', '9ijn', '8uhb', '7ygv', '6tfc', '5rdx', '4esz']
        return keyboardSlopeArr.some(v => pwd.toLowerCase().indexOf(v) > -1)
      }

      if (regex.test(value)) {
        if (sameReg.test(value)) {
          callback(new Error('密码不能含有连续4位相同的数字或字母'))
        } else if (strReg.test(value)) {
          callback(new Error('密码不能含有4位连续的字母'))
        } else if (numReg.test(value)) {
          callback(new Error('密码不能含有4位连续的数字'))
        } else if (keyboardHorizontalReg.test(value)) {
          callback(new Error('密码不能含有4位键盘横向方向连续的字母'))
        } else if (isStrInSlope(value)) {
          callback(new Error('密码不能含有4位键盘斜向方向连续的字符'))
        }
        callback()
      }
      callback(new Error('您的密码复杂度太低, (密码必须8位以上,包含数字,大小写字母或特殊字符), 请重新设置密码'))
    }
    return {
      reFresh: true,
      dialogTitle: '',
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      isDisableAccount: false,
      isShowPwd: false,
      isShowAuth: false,
      isOperateAuth: false,
      pwdInputType: 'password',
      pwdIconType: 'visibility',
      treeSelect: {
        dataTreeList: [],
        clearTreeSelect: 0,
        nodeKey: 'role_id',
        defaultCheckedKeys: [],
        defaultProps: {
          children: 'children',
          label: 'role_name'
        }
      },
      spacesTreeSelect: {
        dataTreeList: [],
        clearTreeSelect: 0,
        nodeKey: 'space_code',
        defaultCheckedKeys: [],
        defaultProps: {
          children: 'children',
          label: 'space_name'
        }
      },
      form: {
        user_account: '',
        user_password: '',
        user_roleids: [],
        is_lock: '1',
        is_restrict_spaces: '1',
        user_spaces: []
      },
      rules: {
        user_account: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        user_password: [
          { required: true, message: '请输入登录新密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      let isValid = false
      switch (this.operate) {
        case 'CREATE':
          isValid =
            this.form.user_roleids.length > 0 &&
            !this.$cFun.isNullOrEmptyForObJ({
              user_account: this.form.user_account,
              user_password: this.form.user_password
            })
          break
        case 'READ':
          break
        case 'UPDATE':
          isValid =
            this.form.user_roleids.length > 0 &&
            !this.$cFun.isNullOrEmptyForObJ({
              user_account: this.form.user_account
            })
          break
        case 'DELETE':
          break
        case 'AUTH':
          isValid =
            (this.form.is_restrict_spaces == '1' ? true : this.form.user_spaces.length > 0 ? true : false) &&
            !this.$cFun.isNullOrEmptyForObJ({
              user_account: this.form.user_account,
              is_lock: this.form.is_lock,
              is_restrict_spaces: this.form.is_restrict_spaces
            })
          break
        case 'RESTPWD':
          isValid = !this.$cFun.isNullOrEmptyForObJ({
            user_account: this.form.user_account,
            user_password: this.form.user_password
          })
          break
      }
      return isValid
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      // console.log("dialogFormData", val);
      this.reFresh = false
      this.form.user_account = val.account
      this.form.user_password = val.user_password
      this.form.user_roleids = typeof val.function_id === 'string' ? val.function_id.split(',') : []
      this.form.is_lock = val.is_lock == 0 ? '1' : '2'
      this.form.user_spaces = val.spacelist
      this.form.is_restrict_spaces = val.spacelist ? (val.spacelist.length > 0 ? '2' : '1') : '1'
      this.treeSelect.dataTreeList = val.roleList
      this.treeSelect.defaultCheckedKeys = val.defaultCheckedKeys
      this.spacesTreeSelect.dataTreeList = val.spacesAllList
      this.spacesTreeSelect.defaultCheckedKeys = val.spacesDefaultCheckedKeys
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val)
      this.isVisible = this.dialogFormVisible
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt
      switch (val) {
        case 'CREATE':
          this.dialogTitle = '创建账户'
          this.isDisable = false
          this.isDisableAccount = false
          this.isShowPwd = true
          this.isShowAuth = true
          this.isOperateAuth = false
          break
        case 'READ':
          this.dialogTitle = '查看账户'
          this.isDisableAccount = true
          this.isDisable = true
          this.isOperateAuth = false
          break
        case 'UPDATE':
          this.dialogTitle = '编辑角色'
          this.isDisable = false
          this.isDisableAccount = true
          this.isShowPwd = false
          this.isShowAuth = true
          this.isOperateAuth = false
          break
        case 'DELETE':
          this.dialogTitle = '删除账户'
          this.isDisable = true
          this.isDisableAccount = true
          this.isShowPwd = false
          this.isShowAuth = true
          this.isOperateAuth = false
          break
        case 'AUTH':
          this.dialogTitle = '权限管理'
          this.isDisable = false
          this.isDisableAccount = true
          this.isShowPwd = false
          this.isShowAuth = false
          this.isOperateAuth = true
          break
        case 'RESTPWD':
          this.dialogTitle = '重置密码'
          this.isDisable = false
          this.isDisableAccount = true
          this.isShowPwd = true
          this.isShowAuth = false
          this.isOperateAuth = false
          break
      }
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.submit()
        } else {
          return false
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el)
      // 判断下操作
      if (this.operate === 'CREATE') {
        await this.addUser()
      } else if (this.operate === 'UPDATE') {
        await this.updateUser()
      } else if (this.operate === 'RESTPWD') {
        await this.restUserPwd()
      } else if (this.operate === 'AUTH') {
        await this.modifyUserSpace()
      }
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 删除事件
    async delRow() {
      let el = event.currentTarget
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, 'danger')
      // 提交API删除角色
      await this.delRole()
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 添加用户
    async addUser() {
      try {
        let params = {
          account: this.form.user_account,
          pwd: md5(this.form.user_password),
          roleids: this.form.user_roleids
        }
        console.log(params)
        let result = await this.$apis.ODC('添加用户', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 更新用户
    async updateUser() {
      try {
        let params = {
          account: this.form.user_account,
          roleids: this.form.user_roleids
        }
        let result = await this.$apis.ODC('修改用户角色', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        console.log(err)
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 重置用户密码
    async restUserPwd() {
      try {
        let params = {
          account: this.form.user_account,
          newpwd: md5(this.form.user_password)
        }
        let result = await this.$apis.ODC('修改用户密码', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        console.log(err)
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 修改用户禁用和SPACE
    async modifyUserSpace() {
      try {
        let params = {
          account: this.form.user_account,
          is_lock: this.form.is_lock == '1' ? 0 : 1,
          space: this.form.is_restrict_spaces == '1' ? [] : this.form.user_spaces
        }
        let result = await this.$apis.ODC('修改用户禁用和SPACE', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        console.log(err)
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 删除角色
    async delRole() {
      try {
        let params = {
          account: this.form.user_account
        }
        let result = await this.$apis.ODC('删除用户', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 表单校验
    isValidForm() {
      return new Promise((resolve, reject) => {
        const isValid = this.$validator.validateAll()
        isValid
          .then(valid => {
            resolve(valid)
          })
          .catch(() => {
            reject(false)
          })
      })
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false
      if (this.isVisible == false) {
        this.emptyData()
        this.$emit('handleDiaLogClose', isRefresh)
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById('content-area')
      if (dom) {
        if (val) {
          dom.setAttribute(
            'class',
            dom
              .getAttribute('class')
              .concat(' ')
              .concat('dialog-show-overlay')
          )
        } else {
          dom.setAttribute(
            'class',
            dom
              .getAttribute('class')
              .replace(' ', '')
              .replace('dialog-show-overlay', '')
          )
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = ''
      switch (state) {
        case 'success':
          title = '成功'
          color = 'success'
          break
        case 'danger':
          title = '错误'
          color = 'danger'
          break
        case 'warning':
          title = '警告'
          color = 'warning'
          break
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    // 获取选中角色
    popoverHide(checkedIds, checkedData) {
      let user_roleids = []
      if (checkedData != undefined && checkedData != null) {
        checkedData.forEach(item => {
          // 提取当前
          user_roleids.push(item.role_id)
        })
      }
      // 提交
      this.form.user_roleids = user_roleids
    },
    // 获取选中空间
    spacesPopoverHide(checkedIds, checkedData) {
      this.form.user_spaces = checkedData
    },
    // 清空数据
    emptyData() {
      this.pwdInputType = 'password'
      this.pwdIconType = 'visibility'
      this.form.user_id = 'default'
      this.form.user_roleids = []
      this.treeSelect.dataTreeList = []
      this.treeSelect.defaultCheckedKeys = []
      this.spacesTreeSelect.dataTreeList = []
      this.spacesTreeSelect.defaultCheckedKeys = []
    },
    // 切换密码框样式
    changePasswordType() {
      if (this.pwdInputType === 'password') {
        this.pwdInputType = 'text'
        this.pwdIconType = 'visibility_off'
      } else {
        this.pwdInputType = 'password'
        this.pwdIconType = 'visibility'
      }
    }
  },
  destroyed() {
    this.changeNavBarShadow()
  },
  components: {
    TreeSelect
  }
}
</script>

<style lang="scss" scope="scope">
.customWidth {
  width: 35%;
  min-width: 300px;
}
// 模态框通用样式
.el-dialog__title {
  .con-vs-avatar {
    float: left;
  }
  .vs-divider {
    margin: 10px 0 !important;
  }
  .line-h-30 {
    line-height: 30px;
  }
}
// 表单通用样式
.el-dialog__body {
  padding: 0px 20px !important;
  .common-form {
    .vs-input--label {
      display: block;
      padding-bottom: 10px;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .vs-input--placeholder {
      padding: 0.7rem 0.7rem 0.7rem 3rem !important;
    }
    .vs-input--icon {
      top: 10px;
    }
  }
}
</style>
